import Icon from '@/assets/icons/people.svg';
import SubIcon from '@/assets/icons/circle_sidebar.svg';
import type { RouterRecord } from '@/types/router';
import { RouteNames } from '@/types/router';
import { AdminModuleIdentifiers } from '@/types/store/user';

export const adminUserSettingsRoutes: RouterRecord = {
	path: '/administrator',
	component: async () => import('@/layout/LayoutIndex.vue'),
	redirect: '/administrator/users',
	name: RouteNames.ADMIN_USER_ROOT,
	meta: {
		title: 'user_settings',
		icon: Icon,
		group: 'admin',
		isNotClickable: true,
		hasHr: true,
		hasSideBarTitle: 'admin_title',
		roles: ['superadmin'],
	},
	children: [
		{
			path: 'users/edit/:id(\\d+)',
			component: async () => import('@/views/maintenance/MaintenanceIndex.vue'),
			name: RouteNames.ADMIN_USER_PROFILE,
			meta: { title: 'userProfile',	id: AdminModuleIdentifiers.USERS },
		},
		{
			path: 'users',
			redirect: '/administrator/users',
			name: RouteNames.ADMIN_USER_MANAGER_MAIN,
			meta: {
				title: 'user_manager',
				icon: SubIcon,
				subItem: 'user-admin-first',
				id: AdminModuleIdentifiers.USERS,
				permissions: ['manage user', 'manage usergroup'],
			},
			children: [
				{
					path: '',
					component: async () => import('@/views/user-manager/UserManager.vue'),
					name: RouteNames.ADMIN_USER,
				},
			],
		},
		{
			path: 'user-groups',
			component: async () => import('@/views/user-manager/UserGroupsManger.vue'),
			name: RouteNames.ADMIN_USER_GROUP,
			meta: {
				id: AdminModuleIdentifiers.USERS_GROUPS,
			},
		},
		{
			path: 'roles',
			component: async () => import('@/views/user-manager/UserRoles.vue'),
			name: RouteNames.ADMIN_USER_ROLES,
			meta: { title: 'rolePermission', icon: SubIcon, subItem: 'user-admin-second', id: AdminModuleIdentifiers.USER_ROLES },
		},
	],
};
