import type { UserModuleIdentifiers, AdminModuleIdentifiers } from '@/types/store/user';

export enum RouteNames {
	ALERTS = 'alerts',
	ALERTS_DASHBOARD = 'alertsDashboard',
	ADMIN_SYSTEM_ROOT = 'administratorSystemRoot',
	ADMIN_SYSTEM_LOCALITY = 'systemSettingsLocality',
	ADMIN_SYSTEM_SUB_LOCALITY = 'subLocality',
	ADMIN_SYSTEM_MODULES = 'modules',
	ADMIN_SYSTEM_CATEGORIES = 'categories',
	ADMIN_SYSTEM_MODULE_DEFAULT = 'modulesDefault',
	ADMIN_SYSTEM_MODULE_CATEGORIES = 'moduleCategories',
	ADMIN_SYSTEM_MODULE_SUBCATEGORIES = 'categoriesSubcategories',
	ADMIN_USER_ROOT = 'administratorRoot',
	ADMIN_USER_PROFILE = 'userProfile',
	ADMIN_USER_MANAGER_MAIN = 'userManagerMainGroup',
	ADMIN_USER = 'usersGroupDefault',
	ADMIN_USER_GROUP = 'userGroup',
	ADMIN_USER_ROLES = 'roleList',
	AIR_QUALITY_ROOT = 'airRoot',
	AIR_QUALITY = 'air',
	APPLICATIONS_ROOT = 'applicationsRoot',
	APPLICATIONS_APPS_INDEX = 'appsIndex',
	APPLICATIONS_APPS_LIST = 'appsList',
	APPLICATIONS_APPS_PAGES_DEFAULT = 'appsPagesDefault',
	APPLICATIONS_APPS_PAGES = 'applicationsLocality',
	APPLICATIONS_APP_DETAIL = 'appDetail',
	APPLICATIONS_APP_CREATE = 'appCreate',
	APPLICATIONS_APP_USERS = 'appUsers',
	APPLICATIONS_APP_INVOICE_DETAIL = 'appInvoiceDetail',
	APPLICATIONS_APP_BILLING = 'appBilling',
	APPLICATIONS_APP_BILLING_REPORTS = 'appBillingReports',
	APPLICATIONS_APP_NOTIFICATIONS = 'appNotifications',
	APPLICATIONS_UI_ROOT = 'applicationsUiRoot',
	APPLICATIONS_APP_UI = 'appUi',
	ARTICLE_ROOT = 'articlesRoot',
	ARTICLE_DEFAULT = 'articlesDefault',
	ARTICLE_LOCALITY = 'articlesLocality',
	ARTICLE_EDIT_FORM = 'articlesEditForm',
	ARTICLE_ADD_FORM = 'articlesAddForm',
	ARTICLE_ROOT_ADD_FORM = 'articlesRootAddForm',
	BIKE_SHARING_ROOT = 'bikeSharingRoot',
	BIKE_SHARING = 'bikeSharing',
	BROADCAST_ROOT = 'broadcastRoot',
	BROADCAST = 'broadcast',
	CAMERAS = 'cameras',
	CAMERAS_CAMERA = 'camerasCamera',
	CAMERAS_DASHBOARD = 'camerasDashboard',
	CHARGING_STATIONS_ROOT = 'chargingStationsRoot',
	CHARGING_STATIONS = 'chargingStations',
	COMMUNITY_ROOT = 'communityRoot',
	COMMUNITY = 'community',
	COMMUNITY_USERS = 'communityUsers',
	DASHBOARD_ROOT = 'dashboardRoot',
	DASHBOARD = 'dashboard',
	ENERGY = 'energy',
	ENERGY_ALERTS = 'energyAlerts',
	ENERGY_DASHBOARD = 'energyDashboard',
	ENERGY_OBJECT = 'energyObject',
	ENERGY_SUPPLY_POINT = 'energySupplyPoint',
	ENVIRONMENT_ROOT = 'environmentRoot',
	ENVIRONMENT = 'environment',
	ENVIRONMENT_ALERTS = 'environmentAlerts',
	ENVIRONMENT_ZONE = 'environmentZone',
	ENVIRONMENT_OBJECT = 'environmentObject',
	ENVIRONMENT_OBJECT_CO2 = 'environmentObjectCo2',
	ENVIRONMENT_OBJECT_MOISTURE_METER = 'environmentObjectMoistureMeter',
	ENVIRONMENT_OBJECT_TRACKER = 'environmentObjectTracker',
	ENVIRONMENT_OBJECT_WEATHER_STATION = 'environmentObjectWeatherStation',
	ENVIRONMENT_OBJECT_WEATHER_STATION_GSM = 'environmentObjectWeatherStationGsm',
	ERROR_ROOT = 'errorPagesRoot',
	ERROR_401 = 'page401',
	ERROR_404 = 'page404',
	EVENTS_ROOT = 'eventsRoot',
	EVENTS_MAP_ROOT = 'eventsMapRoot',
	EVENTS_DEFAULT = 'eventsLocalityDefault',
	EVENTS_LOCALITY = 'eventsLocality',
	EVENTS_EDIT_FORM = 'eventsEditForm',
	EVENTS_TURNSTILES = 'eventsTurnstiles',
	EVENTS_ADD_FORM = 'eventsAddForm',
	EVENTS_ROOT_ADD_FORM = 'eventsRootAddForm',
	EVENTS_SHARED = 'eventsShared',
	INFORMATION_MEDIA_ROOT = 'informationMediaRoot',
	INFORMATION_MEDIA = 'informationMedia',
	LIGHTING_ROOT = 'lightingRoot',
	LIGHTING = 'lighting',
	PARKING_ROOT = 'parkingCityListRoot',
	PARKING = 'parkingCityList',
	PARKING_ALERTS = 'parkingAlerts',
	PARKING_DEFAULT_INDEX = 'parkingDefaultIndex',
	PARKING_ZONE_INDEX = 'parkingZoneIndex',
	PARKING_PLACE_INDEX = 'parkingPlaceIndex',
	POI_ROOT = 'poiRoot',
	POI_DEFAULT = 'poiLocalityDefault',
	POI_TURNSTILES = 'poiTurnstiles',
	POI_LOCALITY = 'poiLocality',
	POI_ADD_FORM = 'poiAddForm',
	POI_ROOT_ADD_FORM = 'poiRootAddForm',
	POI_EDIT_FORM = 'poiEditForm',
	ROUTE_ROOT = 'routeRoot',
	ROUTE_DEFAULT = 'routes',
	ROUTE_FORM = 'routesForm',
	ROUTE_EDIT_FORM = 'routesEditForm',
	SCOOTER_SHARING_ROOT = 'scooterSharingRoot',
	SCOOTER_SHARING = 'scooterSharing',
	TICKETS_ROOT = 'ticketsRoot',
	TICKETS_DEFAULT = 'tickets',
	TICKETS_LOCALITY = 'ticketsLocality',
	TICKETS_DETAIL = 'eventTicketDetail',
	TICKETS_SEASONS = 'ticketSeasons',
	TICKETS_CREATE = 'ticketSeasonsCreate',
	TRAFFIC_ROOT = 'trafficRoot',
	TRAFFIC_CONTROL_POINT = 'trafficControlPoint',
	TRAFFIC_DASHBOARD = 'trafficDashboard',
	TRAFFIC_PATH = 'trafficPath',
	WASTE_ROOT = 'wasteRoot',
	WASTE = 'waste',
	LOGIN = 'login',
	E404 = '404',
	E401 = '401',
	E429 = '429',
	MAINTENANCE_ROOT = 'maintenanceRoot',
	MAINTENANCE = 'maintenance',
}

export interface RouterRecord {
	path: string;
	component?: any;
	name: RouteNames;
	redirect?: string | (() => Promise<{ name: string }>);
	meta?: {
		title?: string;
		icon?: any;
		group?: string;
		hasHr?: boolean;
		hasSideBarTitle?: string;
		isNotClickable?: boolean;
		subItem?: string;
		edit?: boolean;
		root?: boolean;
		permissions?: string[];
		roles?: string[];
		id?: UserModuleIdentifiers | AdminModuleIdentifiers;
		hidden?: boolean;
		cantEnterRoles?: string[];
	};
	children?: RouterRecord[];
	beforeEnter?: (to: any, from: any) => void;
}

export interface UserRouteRecord extends RouterRecord {
	available?: boolean;
}
