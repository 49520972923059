import Icon from '@/assets/icons/water_quality.svg';
import type { RouterRecord } from '@/types/router';
import { RouteNames } from '@/types/router';
import { UserModuleIdentifiers } from '@/types/store/user';

export const communitiesRoutes: RouterRecord = {
	path: '/communities',
	component: async () => import('@/layout/LayoutIndex.vue'),
	meta: { title: 'community', icon: Icon, group: 'modules', id: UserModuleIdentifiers.COMMUNITIES },
	name: RouteNames.COMMUNITY_ROOT,
	children: [
		{
			path: '',
			component: async () => import('@/views/communities/CommunitiesIndex.vue'),
			name: RouteNames.COMMUNITY,
		},
		{
			path: ':id',
			component: async () => import('@/views/communities/CommunitiesUsers.vue'),
			name: RouteNames.COMMUNITY_USERS,
		},
	],
};
