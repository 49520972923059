import { DeviceType } from '@/store/device-metrics';
import { defineAsyncComponent } from 'vue';
import { type Component } from 'vue';
import { RouteNames } from '@/types/router';
import moment from 'moment/moment';

type EnvironmentObjectMapItem = {
	[key: string]: {
		slug: string;
		getRoutePath: (id: string) => string;
		name: RouteNames;
		component: Component;
	};
};

const weatherStationComponent = defineAsyncComponent(async () => import('@/views/environment/EnvironmentObjectWeatherStation.vue'));

const createEnvironmentObjectMapItem = (deviceType: DeviceType, component: Component, name: RouteNames) => {
	const slug = deviceType.replaceAll('_', '-');
	return {
		slug, // to be available with call EnvironmentObjectMap[device.type].slug, so you can do easy routing
		getRoutePath: (id: string) => `/environment/objects/${slug}/${id}`, // usable in router.push(returned value of function)
		component,
		name,
	};
};

export const EnvironmentObjectMap: EnvironmentObjectMapItem = {
	[DeviceType.MOISTURE_METER]: createEnvironmentObjectMapItem(
		DeviceType.MOISTURE_METER,
		defineAsyncComponent(async () => import('@/views/environment/EnvironmentObjectMoistureMeter.vue')),
		RouteNames.ENVIRONMENT_OBJECT_MOISTURE_METER,
	),
	[DeviceType.TRACKER]: createEnvironmentObjectMapItem(
		DeviceType.TRACKER,
		defineAsyncComponent(async () => import('@/views/environment/EnvironmentObjectTracker.vue')),
		RouteNames.ENVIRONMENT_OBJECT_TRACKER,
	),
	[DeviceType.WEATHER_STATION]: createEnvironmentObjectMapItem(DeviceType.WEATHER_STATION, weatherStationComponent, RouteNames.ENVIRONMENT_OBJECT_WEATHER_STATION),
	[DeviceType.WEATHER_STATION_GSM]: createEnvironmentObjectMapItem(DeviceType.WEATHER_STATION_GSM, weatherStationComponent, RouteNames.ENVIRONMENT_OBJECT_WEATHER_STATION_GSM),
};

export const getEnvironmentObjectRoutes = () => Object.values(EnvironmentObjectMap).map(val => ({
	path: `objects/${val.slug}/:identifier`,
	name: val.name,
	component: val.component,
}));

export enum OccupancyTimeGranularity {
	HOUR = '1H',
	DAY = '1D',
	WEEK = '1W',
	MONTH = '1M',
	THREE_MONTHS = '3M',
}

export interface OccupancyInTimeInterval {
	apiValueFrom: () => moment.Moment;
	apiValueBucket: string;
	nextFetchMoment: () => moment.Moment;
	frontendValue: OccupancyTimeGranularity;
	dateLabelFormat: string;
}

export type OccupancyInTimeType = {
	[key in OccupancyTimeGranularity]: OccupancyInTimeInterval;
};

export const OccupancyInTime: OccupancyInTimeType = {
	[OccupancyTimeGranularity.HOUR]: {
		apiValueFrom: () => moment().subtract(1, 'hours'),
		nextFetchMoment: () => moment().add(5, 'minutes'),
		frontendValue: OccupancyTimeGranularity.HOUR,
		apiValueBucket: '5_minutes',
		dateLabelFormat: 'HH:mm',
	},
	[OccupancyTimeGranularity.DAY]: {
		apiValueFrom: () => moment().subtract(23, 'hours'),
		nextFetchMoment: () => moment().add(10, 'minutes'),
		frontendValue: OccupancyTimeGranularity.DAY,
		apiValueBucket: '10_minutes',
		dateLabelFormat: '\xa0HH:mm\xa0',
	},
	[OccupancyTimeGranularity.WEEK]: {
		apiValueFrom: () => moment().subtract(1, 'weeks'),
		nextFetchMoment: () => moment().add(1, 'hour'),
		frontendValue: OccupancyTimeGranularity.WEEK,
		apiValueBucket: '1_hour',
		dateLabelFormat: 'DD.MM. HH:mm',
	},
	[OccupancyTimeGranularity.MONTH]: {
		apiValueFrom: () => moment().subtract(1, 'months'),
		nextFetchMoment: () => moment().add(12, 'hours'),
		frontendValue: OccupancyTimeGranularity.MONTH,
		apiValueBucket: '12_hours',
		dateLabelFormat: 'DD.MM. HH:mm',
	},
	[OccupancyTimeGranularity.THREE_MONTHS]: {
		apiValueFrom: () => moment().subtract(3, 'months'),
		nextFetchMoment: () => moment().add(1, 'day'),
		frontendValue: OccupancyTimeGranularity.THREE_MONTHS,
		apiValueBucket: '1_day',
		dateLabelFormat: 'DD.MM.',
	},
};
