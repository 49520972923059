import { UserModuleIdentifiers } from '@/types/store/user';
import { uniq } from 'lodash';
import { defineStore } from 'pinia';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

export type DeviceMetricsStoreItem = {
	id: string;
	title: string;
	unit: string;
	precision: number;
	deviceTypes: DeviceType[];
	deviceTypesDefault?: DeviceType[];
};

export enum DeviceType {
	CALORIMETER = 'calorimeter',
	CO2 = 'co2',
	ENERGY_METER = 'energy_meter',
	MOISTURE_METER = 'moisture_meter',
	PARKING = 'parking',
	PULSE_COUNTER = 'pulse_counter',
	TRACKER = 'tracker',
	TRAFFIC_CAMERA = 'traffic_camera',
	WEATHER_STATION = 'weather_station',
	WEATHER_STATION_GSM = 'weather_station_gsm',
}

export enum Metric {
	CO2 = 'co2',
	CURRENT_L1 = 'current_l1',
	CURRENT_L2 = 'current_l2',
	CURRENT_L3 = 'current_l3',
	EXPORT_ACTIVE_ENERGY = 'export_active_energy',
	EXPORT_REACTIVE_ENERGY = 'export_reactive_energy',
	FLOW = 'flow',
	GAS = 'gas',
	GAS_CO = 'gas_co',
	GAS_NO2 = 'gas_no2',
	GAS_O3 = 'gas_o3',
	GAS_SO2 = 'gas_so2',
	HEAT = 'heat',
	HUMIDITY = 'humidity',
	IMPORT_ACTIVE_ENERGY = 'import_active_energy',
	IMPORT_REACTIVE_ENERGY = 'import_reactive_energy',
	LIGHT = 'light',
	LOCATION = 'location',
	MAX_DEMAND = 'max_demand',
	MOISTURE = 'moisture',
	PM_1 = 'pm_1',
	PM_10 = 'pm_10',
	PM_2_5 = 'pm_2_5',
	POWER = 'power',
	POWER_FACTOR = 'power_factor',
	PRECIPITATION = 'precipitation',
	PRESSURE = 'pressure',
	SPEED = 'speed',
	TEMPERATURE = 'temperature',
	TEMPERATURE1 = 'temperature1',
	TEMPERATURE2 = 'temperature2',
	TOTAL_ACTIVE_POWER = 'total_active_power',
	TOTAL_REACTIVE_POWER = 'total_reactive_power',
	UV_INDEX = 'uv_index',
	VISIBILITY = 'visibility',
	VOLTAGE_L1 = 'voltage_l1',
	VOLTAGE_L2 = 'voltage_l2',
	VOLTAGE_L3 = 'voltage_l3',
	VOLUME = 'volume',
	WATER = 'water',
	WATER_COLD = 'water_cold',
	WATER_HOT = 'water_hot',
	WIND_SPEED = 'wind_speed',
}

export enum MetricUnit {
	AMPERE = 'A',
	CUBIC_METER = 'm³',
	CUBIC_METER_PER_HOUR = 'm³/h',
	DEGREE_CELSIUS = '°C',
	HECTOPASCAL = 'hPa',
	JOULE = 'J',
	KILOMETER = 'km',
	KILOMETER_PER_HOUR = 'km/h',
	KILOVOLTAMPEREREACTIVEHOUR = 'kvarh',
	KILOWATTHOUR = 'kWh',
	LUX = 'lux',
	METER = 'm',
	METER_PER_SECOND = 'm/s',
	MICROGRAM_PER_CUBIC_METER = 'µg/m³',
	MILLIMETER = 'mm',
	PARTS_PER_BILLION = 'ppb',
	PARTS_PER_MILLION = 'ppm',
	PERCENT = '%',
	SECOND = 's',
	VOLT = 'V',
	WATT = 'W',
}

export enum MetricAggregation {
	AVG = 'avg',
	COUNT = 'count',
	DELTA = 'delta',
	MAX = 'max',
	MIN = 'min',
	SUM = 'sum',
}

export const getModuleDeviceTypes = (module: string): DeviceType[] => {
	switch (module) {
		case UserModuleIdentifiers.PARKING as string:
			return [DeviceType.PARKING];
		case UserModuleIdentifiers.ENERGY as string:
			return [DeviceType.CALORIMETER, DeviceType.ENERGY_METER, DeviceType.PULSE_COUNTER];
		case UserModuleIdentifiers.ENVIRONMENT as string:
			return [DeviceType.CO2, DeviceType.MOISTURE_METER, DeviceType.TRACKER, DeviceType.WEATHER_STATION, DeviceType.WEATHER_STATION_GSM];
		case UserModuleIdentifiers.CAMERAS as string:
			return [DeviceType.TRAFFIC_CAMERA];
		default:
			return [] as DeviceType[];
	}
};

export const roundDecimals = (value: number, decimals = 0) => {
	const factor = (10 ** decimals);

	return Math.round(value * factor) / factor;
};

export const normalizeMetricUnitValue = (unit: MetricUnit, value: number | undefined, decimals = 2): { unit: MetricUnit; value: number | undefined } => {
	if (!value) {
		return { unit, value };
	}

	if (unit === MetricUnit.METER) {
		if (value > 1500) {
			return { unit: MetricUnit.KILOMETER, value: Number(roundDecimals(value / 1000, decimals)) };
		}
	} else if (unit === MetricUnit.METER_PER_SECOND) {
		return { unit: MetricUnit.KILOMETER_PER_HOUR, value: Number(roundDecimals(value * 3.6, decimals)) };
	}

	return { unit, value };
};

export const roundedMetricValueToPrecision = (metricStoreItem: DeviceMetricsStoreItem | undefined, value: number | undefined): number | undefined => metricStoreItem && typeof value !== 'undefined' ? roundDecimals(value, metricStoreItem.precision) : value;

export const useDeviceMetricsStore = defineStore('metrics', () => {
	const { t } = useI18n({ useScope: 'global' });
	const metrics = computed<DeviceMetricsStoreItem[]>(() => [
		{
			id: Metric.CO2,
			title: t('metrics.co2'),
			unit: MetricUnit.PARTS_PER_MILLION,
			precision: 0,
			deviceTypes: [DeviceType.CO2],
			deviceTypesDefault: [DeviceType.CO2],
		},
		{
			id: Metric.CURRENT_L1,
			title: t('metrics.current_l1'),
			unit: MetricUnit.AMPERE,
			precision: 1,
			deviceTypes: [DeviceType.ENERGY_METER],
		},
		{
			id: Metric.CURRENT_L2,
			title: t('metrics.current_l2'),
			unit: MetricUnit.AMPERE,
			precision: 1,
			deviceTypes: [DeviceType.ENERGY_METER],
		},
		{
			id: Metric.CURRENT_L3,
			title: t('metrics.current_l3'),
			unit: MetricUnit.AMPERE,
			precision: 1,
			deviceTypes: [DeviceType.ENERGY_METER],
		},
		{
			id: Metric.EXPORT_ACTIVE_ENERGY,
			title: t('metrics.export_active_energy'),
			unit: MetricUnit.KILOWATTHOUR,
			precision: 1,
			deviceTypes: [DeviceType.ENERGY_METER],
		},
		{
			id: Metric.EXPORT_REACTIVE_ENERGY,
			title: t('metrics.export_reactive_energy'),
			unit: MetricUnit.KILOVOLTAMPEREREACTIVEHOUR,
			precision: 1,
			deviceTypes: [DeviceType.ENERGY_METER],
		},
		{
			id: Metric.FLOW,
			title: t('metrics.flow'),
			unit: MetricUnit.CUBIC_METER_PER_HOUR,
			precision: 1,
			deviceTypes: [DeviceType.CALORIMETER],
		},
		{
			id: Metric.GAS,
			title: t('metrics.gas'),
			unit: MetricUnit.CUBIC_METER,
			precision: 1,
			deviceTypes: [DeviceType.PULSE_COUNTER],
		},
		{
			id: Metric.GAS_CO,
			title: t('metrics.gas_co'),
			unit: MetricUnit.PARTS_PER_MILLION,
			precision: 0,
			deviceTypes: [DeviceType.WEATHER_STATION_GSM],
		},
		{
			id: Metric.GAS_NO2,
			title: t('metrics.gas_no2'),
			unit: MetricUnit.PARTS_PER_MILLION,
			precision: 0,
			deviceTypes: [DeviceType.WEATHER_STATION_GSM],
		},
		{
			id: Metric.GAS_O3,
			title: t('metrics.gas_o3'),
			unit: MetricUnit.PARTS_PER_MILLION,
			precision: 0,
			deviceTypes: [DeviceType.WEATHER_STATION_GSM],
		},
		{
			id: Metric.GAS_SO2,
			title: t('metrics.gas_so2'),
			unit: MetricUnit.PARTS_PER_MILLION,
			precision: 0,
			deviceTypes: [DeviceType.WEATHER_STATION_GSM],
		},
		{
			id: Metric.HEAT,
			title: t('metrics.heat'),
			unit: MetricUnit.JOULE,
			precision: 0,
			deviceTypes: [DeviceType.CALORIMETER],
		},
		{
			id: Metric.HUMIDITY,
			title: t('metrics.humidity'),
			unit: MetricUnit.PERCENT,
			precision: 0,
			deviceTypes: [
				DeviceType.CO2,
				DeviceType.WEATHER_STATION,
				DeviceType.WEATHER_STATION_GSM,
			],
		},
		{
			id: Metric.IMPORT_ACTIVE_ENERGY,
			title: t('metrics.import_active_energy'),
			unit: MetricUnit.KILOWATTHOUR,
			precision: 1,
			deviceTypes: [DeviceType.ENERGY_METER],
		},
		{
			id: Metric.IMPORT_REACTIVE_ENERGY,
			title: t('metrics.import_reactive_energy'),
			unit: MetricUnit.KILOVOLTAMPEREREACTIVEHOUR,
			precision: 1,
			deviceTypes: [DeviceType.ENERGY_METER],
		},
		{
			id: Metric.LIGHT,
			title: t('metrics.light'),
			unit: MetricUnit.LUX,
			precision: 0,
			deviceTypes: [DeviceType.WEATHER_STATION_GSM],
		},
		{
			id: Metric.MAX_DEMAND,
			title: t('metrics.max_demand'),
			unit: MetricUnit.WATT,
			precision: 0,
			deviceTypes: [DeviceType.ENERGY_METER],
		},
		{
			id: Metric.MOISTURE,
			title: t('metrics.moisture'),
			unit: MetricUnit.PERCENT,
			precision: 0,
			deviceTypes: [DeviceType.MOISTURE_METER],
			deviceTypesDefault: [DeviceType.MOISTURE_METER],
		},
		{
			id: Metric.PM_1,
			title: t('metrics.pm_1'),
			unit: MetricUnit.MICROGRAM_PER_CUBIC_METER,
			precision: 1,
			deviceTypes: [DeviceType.WEATHER_STATION_GSM],
		},
		{
			id: Metric.PM_10,
			title: t('metrics.pm_10'),
			unit: MetricUnit.MICROGRAM_PER_CUBIC_METER,
			precision: 1,
			deviceTypes: [DeviceType.WEATHER_STATION_GSM],
		},
		{
			id: Metric.PM_2_5,
			title: t('metrics.pm_2_5'),
			unit: MetricUnit.MICROGRAM_PER_CUBIC_METER,
			precision: 1,
			deviceTypes: [DeviceType.WEATHER_STATION_GSM],
		},
		{
			id: Metric.POWER,
			title: t('metrics.power'),
			unit: MetricUnit.WATT,
			precision: 1,
			deviceTypes: [DeviceType.CALORIMETER],
			deviceTypesDefault: [DeviceType.CALORIMETER],
		},
		{
			id: Metric.POWER_FACTOR,
			title: t('metrics.power_factor'),
			unit: '',
			precision: 2,
			deviceTypes: [DeviceType.ENERGY_METER],
		},
		{
			id: Metric.PRECIPITATION,
			title: t('metrics.precipitation'),
			unit: MetricUnit.MILLIMETER,
			precision: 0,
			deviceTypes: [DeviceType.WEATHER_STATION, DeviceType.WEATHER_STATION_GSM],
		},
		{
			id: Metric.PRESSURE,
			title: t('metrics.pressure'),
			unit: MetricUnit.HECTOPASCAL,
			precision: 0,
			deviceTypes: [DeviceType.WEATHER_STATION, DeviceType.WEATHER_STATION_GSM],
		},
		{
			id: Metric.SPEED,
			title: t('metrics.speed'),
			unit: MetricUnit.KILOMETER_PER_HOUR,
			precision: 1,
			deviceTypes: [DeviceType.TRACKER],
			deviceTypesDefault: [DeviceType.TRACKER],
		},
		{
			id: Metric.TEMPERATURE,
			title: t('metrics.temperature'),
			unit: MetricUnit.DEGREE_CELSIUS,
			precision: 1,
			deviceTypes: [
				DeviceType.CO2,
				DeviceType.MOISTURE_METER,
				DeviceType.WEATHER_STATION,
				DeviceType.WEATHER_STATION_GSM,
			],
			deviceTypesDefault: [DeviceType.WEATHER_STATION, DeviceType.WEATHER_STATION_GSM],
		},
		{
			id: Metric.TEMPERATURE1,
			title: t('metrics.temperature1'),
			unit: MetricUnit.DEGREE_CELSIUS,
			precision: 1,
			deviceTypes: [DeviceType.CALORIMETER],
		},
		{
			id: Metric.TEMPERATURE2,
			title: t('metrics.temperature2'),
			unit: MetricUnit.DEGREE_CELSIUS,
			precision: 1,
			deviceTypes: [DeviceType.CALORIMETER],
		},
		{
			id: Metric.TOTAL_ACTIVE_POWER,
			title: t('metrics.total_active_power'),
			unit: MetricUnit.KILOWATTHOUR,
			precision: 1,
			deviceTypes: [DeviceType.ENERGY_METER],
			deviceTypesDefault: [DeviceType.ENERGY_METER],
		},
		{
			id: Metric.TOTAL_REACTIVE_POWER,
			title: t('metrics.total_reactive_power'),
			unit: MetricUnit.KILOVOLTAMPEREREACTIVEHOUR,
			precision: 1,
			deviceTypes: [DeviceType.ENERGY_METER],
		},
		{
			id: Metric.UV_INDEX,
			title: t('metrics.uv_index'),
			unit: '',
			precision: 0,
			deviceTypes: [DeviceType.WEATHER_STATION, DeviceType.WEATHER_STATION_GSM],
		},
		{
			id: Metric.VISIBILITY,
			title: t('metrics.visibility'),
			unit: MetricUnit.METER,
			precision: 0,
			deviceTypes: [DeviceType.WEATHER_STATION_GSM],
		},
		{
			id: Metric.VOLTAGE_L1,
			title: t('metrics.voltage_l1'),
			unit: MetricUnit.VOLT,
			precision: 1,
			deviceTypes: [DeviceType.ENERGY_METER],
		},
		{
			id: Metric.VOLTAGE_L2,
			title: t('metrics.voltage_l2'),
			unit: MetricUnit.VOLT,
			precision: 1,
			deviceTypes: [DeviceType.ENERGY_METER],
		},
		{
			id: Metric.VOLTAGE_L3,
			title: t('metrics.voltage_l3'),
			unit: MetricUnit.VOLT,
			precision: 1,
			deviceTypes: [DeviceType.ENERGY_METER],
		},
		{
			id: Metric.VOLUME,
			title: t('metrics.volume'),
			unit: MetricUnit.CUBIC_METER,
			precision: 1,
			deviceTypes: [DeviceType.CALORIMETER],
		},
		{
			id: Metric.WATER,
			title: t('metrics.water'),
			unit: MetricUnit.CUBIC_METER,
			precision: 1,
			deviceTypes: [DeviceType.PULSE_COUNTER],
		},
		{
			id: Metric.WATER_COLD,
			title: t('metrics.water_cold'),
			unit: MetricUnit.CUBIC_METER,
			precision: 1,
			deviceTypes: [DeviceType.PULSE_COUNTER],
		},
		{
			id: Metric.WATER_HOT,
			title: t('metrics.water_hot'),
			unit: MetricUnit.CUBIC_METER,
			precision: 1,
			deviceTypes: [DeviceType.PULSE_COUNTER],
		},
		{
			id: Metric.WIND_SPEED,
			title: t('metrics.wind_speed'),
			unit: MetricUnit.METER_PER_SECOND,
			precision: 1,
			deviceTypes: [DeviceType.WEATHER_STATION, DeviceType.WEATHER_STATION_GSM],
		},
	]);

	const getDeviceTypeMetrics = (deviceTypes: string[]): DeviceMetricsStoreItem[] => metrics.value.filter((item: DeviceMetricsStoreItem) => item.deviceTypes.filter((deviceType: string) => deviceTypes.includes(deviceType)).length > 0);
	const getDeviceTypeMetric = (metric: Metric | string): DeviceMetricsStoreItem | undefined => metrics.value.find((item: DeviceMetricsStoreItem) => item.id === metric);
	const hasDeviceTypeMetric = (deviceTypes: string[], metric: Metric | string): boolean => getDeviceTypeMetrics(deviceTypes).findIndex((item: DeviceMetricsStoreItem) => item.id === metric) >= 0;
	const getDeviceTypeDefaultMetric = (deviceType: DeviceType | string): Metric => metrics.value.find((item: DeviceMetricsStoreItem) => item.deviceTypesDefault?.includes(deviceType as DeviceType))?.id as Metric;
	const getDeviceTypeMetricUnits = (deviceTypes: string[]): string[] => uniq(getDeviceTypeMetrics(deviceTypes)?.map((item: DeviceMetricsStoreItem) => item.unit) ?? [] as string[]).sort();

	return {
		metrics,
		getDeviceTypeMetrics,
		getDeviceTypeMetric,
		hasDeviceTypeMetric,
		getDeviceTypeDefaultMetric,
		getDeviceTypeMetricUnits,
	};
});
