export enum UserModuleIdentifiers {
	ALERTS = 'ALERTS',
	APPLICATIONS = 'APPLICATIONS',
	ARTICLES = 'ARTICLES',
	CAMERAS = 'CAMERAS',
	COMMUNITIES = 'COMMUNITIES',
	ENERGY = 'ENERGY',
	ENVIRONMENT = 'ENVIRONMENT',
	EVENTS = 'EVENTS',
	INFORMATION_MEDIA = 'INFORMATION_MEDIA',
	PARKING = 'PARKING',
	POI = 'POI',
	TICKETS = 'TICKETS',
	TRAFFIC = 'TRAFFIC',
	LIGHTING = 'LIGHTING',
	WASTE = 'WASTE',
	BROADCAST = 'BROADCAST',
	AIR_QUALITY = 'AIR_QUALITY',
	DIGITAL_SIGNAGE = 'DIGITAL_SIGNAGE',
	CHARGING_STATIONS = 'CHARGING_STATIONS',
	BIKE_SHARING = 'BIKE_SHARING',
	SCOOTER_SHARING = 'SCOOTER_SHARING',
	ROUTES = 'ROUTES',
}

export enum AdminModuleIdentifiers {
	LOCALITY = 'LOCALITY',
	MODULES = 'MODULES',
	CATEGORIES = 'CATEGORIES',
	USERS = 'USERS',
	USERS_GROUPS = 'USERS_GROUPS',
	USER_ROLES = 'USER_GROUPS',
}

export enum Division {
	MODERN_TECHNOLOGIES = 1,
	CONTENT_HUB = 2,
}

export interface UserInfo {
	email: string;
	password: string;
}

export interface Module {
	available?: boolean;
	description: Record<string, string> | string;
	division: {
		id: Division;
		name: string;
		id_string: string;
	};
	id: number;
	id_string: UserModuleIdentifiers;
	name: Record<string, string> | string;
	type: 'ORDER' | 'VIEW';
	route?: string;
	constant?: Record<string, unknown[]>;
	statuses: Array<{
		id: number;
		id_string: string;
		name: string;
	}>;
	seq?: number;
	categories_count?: number;
	children_count?: number;
}

export interface ModuleRequest extends ModuleFilter {
	limit?: number;
	page?: number;
	include?: string;
}

interface ModuleFilter {
	search?: string;
	filter: {
		parent_id?: string;
		id?: string;
	};
}

export type UserModules = {
	[key in UserModuleIdentifiers]?: Module
};

export interface UserState {
	id: number | undefined;
	name: string;
	email: string;
	roles: string[];
	permissions: string[];
	modules: UserModules;
	lang: string;
}
